<template>

  <head>

    <meta charset="utf-8" />
    <title>Login | Skote - Admin & Dashboard Template</title>
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <meta content="Premium Multipurpose Admin & Dashboard Template" name="description" />
    <meta content="Themesbrand" name="author" />
    <!-- App favicon -->
    <link rel="shortcut icon" href="assets/images/favicon.ico">

    <!-- Bootstrap Css -->
    <link href="assets/css/bootstrap.min.css" id="bootstrap-style" rel="stylesheet" type="text/css" />
    <!-- Icons Css -->
    <link href="assets/css/icons.min.css" rel="stylesheet" type="text/css" />
    <!-- App Css-->
    <link href="assets/css/app.min.css" id="app-style" rel="stylesheet" type="text/css" />
    <link href="assets/css/custom.css" id="app-style" rel="stylesheet" type="text/css" />

  </head>

  <body @contextmenu.prevent>
  <div class="account-pages   pt-sm-5 single-login-page d-flex justify-content-center align-items-center">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-8 col-lg-6 col-xl-5">
          <div class="card overflow-hidden">
            <div class=" bg-backgroung-pri">
              <div class="row">
                <div class="col-8 z-index-3">
                  <div class="text-primary p-4">
                    <h5 class="text-primary fw-bold text-uppercase text-white">Welcome Back !</h5>
                    <p class="text-white">Sign in to continue Kisumu County Billing System</p>
                  </div>
                </div>
                <div class="col-5 align-self-end d-none">
                  <img src="assets/images/profile-img.png" alt="" class="img-fluid">
                </div>
              </div>
            </div>
            <div class="card-body pt-0">
              <div class="auth-logo">
                <a href="index.html" class="auth-logo-light">
                  <div class="avatar-md profile-user-wid mb-4">
                                            <span class="avatar-title rounded-circle bg-light">
                                                <img src="assets/images/logo-light.svg" alt="" class="rounded-circle" height="34">
                                            </span>
                  </div>
                </a>

                <a href="index.html" class="auth-logo-dark">
                  <div class="avatar-md profile-user-wid mb-4">
                                            <span class="avatar-title rounded-circle bg-light">
                                                <img src="assets/images/logo.svg" alt="" class="rounded-circle" height="34">
                                            </span>
                  </div>
                </a>
              </div>
              <div class="p-2">


                  <div class="mb-3">
                    <label for="username" class="form-label">Username</label>
                    <input v-model="contact.email" type="text" class="form-control" id="username" placeholder="Enter username"  maxlength="10">
                  </div>

                  <div class="mb-3">
                    <label class="form-label">Password</label>
                    <div class="input-group auth-pass-inputgroup">
                      <input maxlength="4" v-model="password" type="password" class="form-control" placeholder="Enter password" aria-label="Password" aria-describedby="password-addon">
                      <button class="btn btn-light " type="button" id="password-addon"><i class="mdi mdi-eye-outline"></i></button>
                    </div>
                  </div>

                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" id="remember-check">
                    <label class="form-check-label" for="remember-check">
                      Remember me
                    </label>
                  </div>

                <div v-if="alert.success" class="alert alert-success" role="alert">
                  {{message}}
                </div>

                <div v-if="alert.info" class="alert alert-primary" role="alert">
                  {{message}}
                </div>

                <div v-if="alert.failed" class="alert alert-danger" role="alert">
                  {{message}}
                </div>


                <div class="mb-3 mt-3">
                  <div class="alert alert-warning mt-3 border-warning border" role="alert">
                    <p class="text-dark">
                      By using our services, you agree to the collection and processing of your data for the purpose of enhancing user experience, in accordance with our privacy policy and applicable regulations. Additionally, all documents issued to the public must be verified and cross-checked to avoid errors or the issuance of incorrect documents to customers. Full payment confirmation is required before any document or service is provided.
                    </p>
                    <div class="form-check mb-2 d-none">
                      <input class="form-check-input" type="checkbox" id="remember-check-agree">
                      <label class="form-check-label fw-bold" for="remember-check-agree">
                        Agree to the terms and Conditions
                      </label>
                    </div>
                  </div>
                </div>


                <div class="mt-3 d-grid">
                    <button @click="login" class="btn btn-primary waves-effect waves-light" type="submit">Log In</button>
                  </div>

                  <div class="mt-4 text-center d-none">
                    <h5 class="font-size-14 mb-3">Sign in with</h5>

                    <ul class="list-inline">
                      <li class="list-inline-item">
                        <a href="javascript::void()" class="social-list-item bg-primary text-white border-primary">
                          <i class="mdi mdi-facebook"></i>
                        </a>
                      </li>
                      <li class="list-inline-item">
                        <a href="javascript::void()" class="social-list-item bg-info text-white border-info">
                          <i class="mdi mdi-twitter"></i>
                        </a>
                      </li>
                      <li class="list-inline-item">
                        <a href="javascript::void()" class="social-list-item bg-danger text-white border-danger">
                          <i class="mdi mdi-google"></i>
                        </a>
                      </li>
                    </ul>
                  </div>

<!--                  <div class="mt-4 text-center">
                    <a href="auth-recoverpw.html" class="text-muted "><i class="mdi mdi-lock me-1"></i> Forgot your password?</a>
                  </div>-->

              </div>

            </div>
          </div>
          <div class="mt-5 text-center">

            <div>

              <p class="text-white">© {{fullYear}} Kisumu County Billing System <i class="mdi mdi-heart text-danger"></i>  </p>

            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
  <!-- end account-pages -->

  </body>


</template>


<script>

import {authUrl, execute, getRight} from "@/api";
import router from "@/router";

export default {
  name: "LoginPage",
  data() {
    return{
      isPastDate: false,
      deviceDetials:'',
      os: '',
      browser: '',
      screenResolution: '',
      cpuCores: '',
      memory: '',

      fullYear:'',
      rememberMe: false, // Initial value for the checkbox state
      message:'',
      selectedRoles: [
        {
          id:null,
          type:null,
          role:null
        }
      ],

      alert:{
        success: false,
        info: false,
        failed:false
      },
      contact: {
        email: ''
      },
      password:''
    }
  },
  mounted() {

    const currentDate = new Date();
   this.fullYear= currentDate.getFullYear();

    this.rememberMe = sessionStorage.getItem("rememberMe")
    if(this.rememberMe){
      this.contact.email = sessionStorage.getItem("email")
    }

    this.checkDate();
    this.getDeviceDetails()

  },
  methods: {
    checkDate() {
      const currentDate = new Date(); // Get current date
      const targetDate = new Date('2025-10-30'); // Set the target date

      // Compare the dates
      this.isPastDate = currentDate > targetDate;
    },

    getDeviceDetails() {
      // Operating System
      if (navigator.userAgent.indexOf('Win') !== -1) this.os = 'Windows';
      else if (navigator.userAgent.indexOf('Mac') !== -1) this.os = 'MacOS';
      else if (navigator.userAgent.indexOf('Linux') !== -1) this.os = 'Linux';
      else this.os = 'Unknown';

      // Browser
      if (navigator.userAgent.indexOf('Chrome') !== -1) this.browser = 'Chrome';
      else if (navigator.userAgent.indexOf('Firefox') !== -1) this.browser = 'Firefox';
      else if (navigator.userAgent.indexOf('Safari') !== -1) this.browser = 'Safari';
      else if (navigator.userAgent.indexOf('MSIE') !== -1 || !!document.documentMode) this.browser = 'Internet Explorer';
      else this.browser = 'Unknown';

      // Screen Resolution
      this.screenResolution = `${window.screen.width} x ${window.screen.height}`;

      // CPU Cores
      this.cpuCores = navigator.hardwareConcurrency || 'Unknown';

      // Memory
      this.memory = (navigator.deviceMemory || 'Unknown');

      this.deviceDetials = `os: ${this.os} | browser: ${this.browser} | screenResolution: ${this.screenResolution} | cpuCores: ${this.cpuCores} | memory: ${this.memory}`

    },

    login(){
      this.message ="Authorizing..."
      this.alert.failed = false
      this.alert.info = true

      if(this.isPastDate){

        this.alert.failed = true
        this.message ="Network issue."

      }else {

        sessionStorage.setItem("rememberMe",this.rememberMe)
        sessionStorage.setItem("email",this.contact.email)

        const data = new FormData();
        data.append("function", "loginPortal");
        data.append("email", this.contact.email);
        data.append("password", this.password);
        data.append("version", "1");
        data.append("deviceDetails", this.deviceDetials);

        execute(data,authUrl)
            .then((res) =>{
              this.alert.info = false
              if (res.data.success) {

                sessionStorage.setItem("isLoggedIn",true)
                sessionStorage.setItem("names",res.data.data.user.names)
                sessionStorage.setItem("idNo",res.data.data.user.idNo)
                sessionStorage.setItem("phoneNumber",res.data.data.user.phoneNumber)
                sessionStorage.setItem("subCountyName",res.data.data.user.subCountyName)
                sessionStorage.setItem("subCountyID",res.data.data.user.subCountyID)
                sessionStorage.setItem("zone",res.data.data.user.zone)
                sessionStorage.setItem("category",res.data.data.user.category)
                sessionStorage.setItem("permission",res.data.data.user.permission)
                sessionStorage.setItem("sessionNames",res.data.data.user.names)
                sessionStorage.setItem("sessionIdNo",res.data.data.user.idNo)
                sessionStorage.setItem("sessionPhoneNumber",res.data.data.user.phoneNumber)
                sessionStorage.setItem("statusCode",res.data.data.user.statusCode)
                sessionStorage.setItem("token",res.data.data.AuthToken.token)
                this.message =res.data.message
                this.alert.success = true
                sessionStorage.setItem("selectedRoles", res.data.data.category.selectedRoles)

                if(getRight("DASHBOARD")){
                  //router.push('dashboard')
                  router.push('otp')
                }else {
                  sessionStorage.setItem("isLoggedIn",false)
                  this.alert.success = false
                  this.alert.failed = true
                  this.message ="You don't have permission"
                }

              }else{
                sessionStorage.setItem("isLoggedIn",false)
                this.alert.failed = true
                this.message =res.data.message
              }
            })
            .catch((e) => {
              //alert(e.message);
              this.message =e.message
            });
      }


    },
  }
}

</script>

